import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55')
];

export const server_loads = [];

export const dictionary = {
		"/": [~7],
		"/account-deleted-relogin": [11],
		"/album/[albumId]": [12],
		"/campaign/[campaignName]": [13],
		"/currency-faqs": [14],
		"/delete-account-faq": [15],
		"/download": [16],
		"/embed/[videoId]": [17],
		"/gamification/benefits": [19],
		"/gamification/newTrophies": [20,[2]],
		"/gamification/newTrophies/editProfile": [21,[2]],
		"/gamification/newTrophies/trophy-view": [22,[2]],
		"/gamification/trophies": [23],
		"/gamification/[category]/[subCategory]/[achievementType]": [18],
		"/gaming-on-moj": [24],
		"/grievance": [25],
		"/kitchen-ministers": [26],
		"/knowledge-saptah": [27],
		"/lens/[id]": [28],
		"/live-spot/faqs": [29],
		"/live-web/stream/[streamId]": [30],
		"/live[...streamOrSchedule]": [31],
		"/login": [32,[3]],
		"/login/phone": [33,[3]],
		"/marketplace-tc": [34,[4]],
		"/moj-for-creators": [35],
		"/moj-spot/faqs": [36],
		"/music/download": [38],
		"/music/feed/[audioId]": [39],
		"/music/[audioId]": [37],
		"/opt-in": [40,[5]],
		"/opt-in/monetization-policy": [41,[5]],
		"/opt-in/status": [42,[5]],
		"/payments/[...any]": [43],
		"/redirect": [44],
		"/request-account-delete": [45],
		"/short-video-app": [46],
		"/stories/[creatorId]/[storyId]": [47],
		"/tag/[tagId]": [48],
		"/tag/[tagId]/feed": [49],
		"/video/[videoId]": [~50],
		"/wallet": [51,[6]],
		"/wallet/transaction": [52,[6]],
		"/wallet/transaction/failed": [53,[6]],
		"/wiki": [54],
		"/wiki/[handle=mojHandle]": [55],
		"/[handle=mojHandle]": [8],
		"/[handle=mojHandle]/feed": [9],
		"/[handle=mojHandle]/video/[videoId]": [~10]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';